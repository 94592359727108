import React, {useContext} from "react";
import {Helmet} from "react-helmet"
import {GTagID} from '../config/settings.json';
import { useLocation } from 'react-router-dom'
import { AppContext } from "../context/AppContext";

export const SEO = () => {
  const slug = useLocation().pathname.substring(1);
  let page = 'home';
  let title = '';
  let description = '';

  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);

  const meta = results.filter((v) =>
    v.dataType.toLowerCase().includes("pagemeta")
  );
  
  if (slug) {
    page = slug;
  }

  if (meta[0]) {
    const current = meta[0].data.filter((item: any) =>
    item.data.pageSlug.iv.toLowerCase().includes(page)
    );
    if (current[0]){
      title = current[0].data.PageTitle['iv'];
      description = current[0].data.PageDesctription['iv'];
    }
  }

  const gt = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${GTagID}');`
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title}></meta>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GTagID}`}></script>
        <script>
          {gt}
        </script>
      </Helmet>
    </div>
  );
}