import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Hero from "./components/Hero";
import Tomeis from "./components/Tomeis";
import FrontEtairos from "./components/FrontEtairos";
import Etairoi from "./components/Etairoi";
import Loading from "../../components/Loader";
import { assetsUrl } from "../../config/settings.json";
import { Link } from "react-router-dom";
import './Home.scss';

export default function Home(): ReactElement {
  const { appData, isLoading, setIsLoading } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const homePage = results.filter((v) =>
    v.dataType.toLowerCase().includes("homepage")
  );
  const etairoi = results.filter((v) =>
    v.dataType.toLowerCase().includes("etairoi")
  );
  const settings = results.filter((v) =>
  v.dataType.toLowerCase().includes("settings")
);

  let heroData: any[] = [];
  let tomeisData: any[] = [];
  let frontEtairosData: any[] = [];
  if (homePage[0]) {
    const heroKeys = Object.keys(homePage[0].data).filter((key) =>
      key.startsWith("intro")
    );
    heroKeys &&
      heroKeys.map((key: any) =>
        heroData.push({ [key]: homePage[0].data[key] })
      );

    const tomeisKeys = Object.keys(homePage[0].data).filter((key) =>
      key.startsWith("section")
    );
    tomeisKeys &&
      tomeisKeys.map((key: any) =>
        tomeisData.push({ [key]: homePage[0].data[key] })
      );

    const frontEtairosKeys = Object.keys(homePage[0].data).filter((key) =>
      key.startsWith("frontPageEtairos")
    );
    frontEtairosKeys &&
      frontEtairosKeys.map((key: any) =>
        frontEtairosData.push({ [key]: homePage[0].data[key] })
      );
    setIsLoading(false);
  }

  return (
    <>
      <div
        className="loader-container"
        style={{ visibility: isLoading ? "visible" : "hidden" }}
      >
        <Loading />
      </div>
      {settings[0] && (
          <div className="container --header-banner">
            <Link
              to={{
                pathname: `/page/${settings[0].data?.footerBannerUrl.iv}`,
              }}
            >
              <img
                src={`${assetsUrl}/${settings[0].data?.footerBannerImage.iv[0]}/?width=800}`}
                alt=""
              />
            </Link>
          </div>
        )}
      {heroData && <Hero data={heroData} />}
      {tomeisData && <Tomeis data={tomeisData} />}
      {frontEtairosData && <FrontEtairos data={frontEtairosData} />}
      {etairoi && <Etairoi data={etairoi} />}
    </>
  );
}
