import React, { ReactElement } from 'react';

interface Props {
	data: any[];
}
export default function EtairoiSynergates(props: Props): ReactElement {
	const { data } = props;
	const synergates = Object.assign({}, ...data);

	return (
		<>
			{synergates && (
				<div className="Teaser">
					<div className="Teaser-top ">
						<svg>
							<use xlinkHref="#logo" />
						</svg>
						<div className="Teaser-title">{synergates.data?.synergatesTeaserTitle['iv']}</div>
						<div className="Teaser-text">{synergates.data?.synergatesTeaserText['iv']}</div>
					</div>
				</div>
			)}
		</>
	)
}