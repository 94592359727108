import React, { ReactElement } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { assetsUrl } from '../../../config/settings.json';
import './Etairoi.scss';

interface Props {
	data: any[];
}
export default function Etairoi(props: Props): ReactElement {
	const { data } = props;
	var Etairoi = data[0]?.data;


	return (
		<>
			<div className="Etairoi">
				{Etairoi && (
					<div className="Etairoi-list">
						{ Etairoi.filter((el: { data: { frontPage: { [x: string]: boolean; }; }; }) => el.data.frontPage['iv'] === true).map((etairos: any, i: number) => (
								<Link to={`/etairoi/${etairos.data.friendlyUrl['iv']}`}>
							 <Fade  key={i} direction="right" delay={i*300} triggerOnce={true}>
							<div  className="etairos" style={{
								backgroundImage: `url(${assetsUrl}/${etairos.data.image['iv'][0]}?width=500)`
							}}>
								<div className="etairos-content">
									<div className="etairos-name">{etairos.data.name['iv']}</div>
									<div className="etairos-subtitle">{etairos.data.title['iv']} </div>
									<div className="etairos-more">περισσότερα</div>
								</div>
							</div>
							 </Fade>
									</Link>
						))}
					</div>
				)}
			</div>
		</>
	);

}
