import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import './SymmetoxesTeaser.scss';

export default function SymmetoxesTeaser(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const symmetoxes = results.filter((v) =>
    v.dataType.toLowerCase().includes("symmetoxes")
  );


  return (
    <>
      {symmetoxes && (
        <div className="SymmetoxesTeaser">
          <div className="container">
          <Link to="/symmetohes" className="Teaser-link">
            <div className="Teaser-texts ">
              <div className="Teaser-title">
              ΣΥΛΛΟΓΙΚΑ ΕΡΓΑ / ΣΥΜΜΕΤΟΧΕΣ
              </div>
              <div className="Teaser-text" dangerouslySetInnerHTML={{ __html: symmetoxes[0]?.data.introText["iv"] }} />
            </div>
          </Link>
          </div>
        </div>
      )}
    </>
  );
}
