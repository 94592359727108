import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "./About.scss";

export default function About(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);

  const pages = results.filter((v) =>
    v.dataType.toLowerCase().includes("homepage")
  );

  //console.log(pages[0]?.data.introLinkText["iv"]);

  return (
    <div className="About-page">
      <div className="container">
        {pages[0] && (
          <div className="About-inner">
            <h2 className="Page-Title">{pages[0]?.data.introLinkText["iv"]}</h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: pages[0]?.data.introFullText["iv"],
              }}
            />
            <div className="divider-bg"></div>
            <div className="divider shadow extra"></div>
          </div>
        )}
      </div>
    </div>
  );
}
