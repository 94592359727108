import React, { ReactElement, useContext } from 'react';
import { AppContext } from '../../context/AppContext';
// import { Link, useParams } from 'react-router-dom';
import EtairoiIndex from './components/EtairoiIndex';
import EtairoiDetails from './components/EtairoiDetails';
import EtairoiSynergates from './components/EtairoiSynergates';
import SynergatesTeaser from '../../components/SynergatesTeaser';

import './Etairoi.scss';

interface Props {
	routerProps: any;
	id: any;
}

export default function Etairoi(props: Props): ReactElement {
	const { routerProps, id } = props
	const { appData } = useContext(AppContext);
	var slug = routerProps.match.params.slug;

	const results = Object.keys(appData).map((key) => appData[key]);

	const etairoi = results.filter(v => v.dataType.toLowerCase().includes('etairoi'));
	const synergates = results.filter(v => v.dataType.toLowerCase().includes('synergatespage'));

	if (!slug && id !== null) {
		slug = id
		//console.log(slug)
	}  else {
		//console.log(slug)
	}

	return (
		<>
		<div className="Etairoi-page">
				{!slug && !id &&  (
					<EtairoiIndex data={etairoi} />
				)}
				{slug && (
					<EtairoiDetails data={etairoi} slug={slug} />
				)}
			{!slug && id &&(
				<EtairoiSynergates data={synergates} />
			)}
		</div>
		{!slug && !id &&  (
			<SynergatesTeaser />
		)}
</>
	);
}
