import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Fade } from "react-awesome-reveal";

import "./TomeisTeaser.scss";

export default function TomeisTeaser(): ReactElement {
  const { appData } = useContext(AppContext);

  const results = Object.keys(appData).map((key) => appData[key]);

  const homePage = results.filter((v) =>
    v.dataType.toLowerCase().includes("homepage")
  );

  let tomeisData: any[] = [];

  if (homePage[0]) {
    const tomeisKeys = Object.keys(homePage[0].data).filter((key) =>
      key.startsWith("section")
    );
    tomeisKeys &&
      tomeisKeys.map((key) =>
        tomeisData.push({ [key]: homePage[0].data[key] })
      );
    var tomeis = Object.assign({}, ...tomeisData);
  }
  return (
    <>
      {tomeis && (
        <div className="Tomeis Teaser">
          <div className="Teaser-top ">
            <Fade triggerOnce={true}>
              <svg>
                <use xlinkHref="#logo" />
              </svg>
            </Fade>
            <Fade triggerOnce={true} delay={150}>
              <div className="Teaser-title">ΤΟΜΕΙΣ ΕΞΕΙΔΙΚΕΥΣΗΣ</div>
            </Fade>
            <Fade triggerOnce={true} delay={150}>
              <div className="Teaser-text">{tomeis?.section2Text["iv"]}</div>
            </Fade>
            <Fade triggerOnce={true} direction="left">
              <div className="Teaser-link">
                <Link to="/tomeis-exeidikeyshs" className="">
                  <svg>
                    <use xlinkHref="#right-arrow" />
                  </svg>
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      )}
    </>
  );
}
