import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { assetsUrl } from "../../config/settings.json";

import TomeisTeaser from "../../components/TomeisTeaser";
import "./Synergates.scss";

export default function Synergates(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const synergatesPage = results.filter((v) => v.dataType === "synergatespage");
  const synergates = results.filter((v) => v.dataType === "synergazomenoi");

  return (
    <div className="Synergates-page">
      <div className="container">
        {synergatesPage && (
          <div className="synergates-intro">
            <h2 className="Page-Title">
              {synergatesPage[0]?.data.synergatesPageTitle["iv"]}
            </h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: synergatesPage[0]?.data.synergatesPageText["iv"],
              }}
            />
          </div>
        )}
        {synergates && (
          <>
            {synergates[0]?.data.map((area: any, i: number) => (
              <div className="area" key={i} id={`area-${i}`}>
                <div className="area-title">
                  {area.data.area?.iv} [{area.data?.synergaths["iv"].length}]
                </div>
                <div className="synergates-list">
                  {area.data?.synergaths["iv"].map(
                    (synergatis: any, j: number) => (
                      <div className="synergaths" key={j}>
                        
                          { synergatis.cvFile 
                          ? 
                          <Link to={{ pathname: `${assetsUrl}/${synergatis.cvFile}`, }} target="_blank">
                          <div className="synergaths-name">{synergatis.name}</div>
                        </Link>
                          :
                          <div className="synergaths-name">{synergatis.name}</div>
                          }
                        
                        <div className="synergaths-title">
                          {synergatis.title}
                        </div>
                        {synergatis.address && (
                          <div className="synergaths-address">
                            {synergatis.address}
                          </div>
                        )}
                        {synergatis.phone && (
                          <div className="synergaths-phone">
                            <b>T:</b> {synergatis.phone}
                          </div>
                        )}
                        {synergatis.cell && (
                          <div className="synergaths-cell">
                            <b>K:</b> {synergatis.cell}
                          </div>
                        )}
                        {synergatis.fax && (
                          <div className="synergaths-fax">
                            <b>F:</b> {synergatis.fax}
                          </div>
                        )}
                        {synergatis.email && (
                          <div className="synergaths-email">
                            <b>E:</b> {synergatis.email}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <TomeisTeaser />
    </div>
  );
}
