import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Fade } from "react-awesome-reveal";
import { assetsUrl } from "../../config/settings.json";
import VivliaTeaser from '../../components/VivliaTeaser';
import "./Symmetohes.scss";

export default function Symmetohes(): ReactElement {
  const { appData } = useContext(AppContext);

  const results = Object.keys(appData).map((key) => appData[key]);

  const symmetohes = results.filter((v) =>
    v.dataType.toLowerCase().includes("symmetoxes")
  );

  return (
    <div className="Vivlia-page">
      <div className="container">
        {symmetohes && (
          <div className="">
            <h2 className="Page-Title">{symmetohes[0]?.data.title["iv"]}</h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: symmetohes[0]?.data.introText["iv"],
              }}
            />

            {symmetohes[0]?.data.symmetoxesList["iv"].map(
              (item: any, i: number) => (
                <Fade triggerOnce={true}>
                  <div
                    key={i}
                    className="Vivlio"
                    style={{
                      backgroundImage: `url(${assetsUrl}/${item.symmetoxhImage}/?width=1500)`,
                    }}
                  >
                    <div
                className="mobileImage"
                style={{
                  backgroundImage: `url(${assetsUrl}/${item.symmetoxhImage}/?width=1500)`,
                }}
              ></div>
                    <div className="Vivlio-content">
                      <div
                        className="vivlio-text"
                        dangerouslySetInnerHTML={{ __html: item.symmetoxhText }}
                      />
                    </div>
                  </div>
                </Fade>
              )
            )}
          </div>
        )}
      </div>
      <VivliaTeaser />
    </div>
  );
}
