import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import './SynergatesTeaser.scss';

export default function SynergatesTeaser(): ReactElement {
  const { appData } = useContext(AppContext);
	
	const results = Object.keys(appData).map((key) => appData[key]);

	const synergatesData = results.filter(v => v.dataType.toLowerCase().includes('synergatespage'));

  const synergates = Object.assign({}, ...synergatesData);

	return (
		<>
			{synergates && (
				<div className="Synergates Teaser">
					<div className="Teaser-top ">
						<svg>
							<use xlinkHref="#logo" />
						</svg>
						<div className="Teaser-title">{synergates.data?.synergatesPageTitle['iv']}</div>
						<div className="Teaser-text">{synergates.data?.synergatesPageText['iv']}</div>
            <div className="Teaser-link">
              <Link to="/synergazomenoi-dikhgoroi" className="">
								<svg>
									<use xlinkHref="#right-arrow" />
								</svg>
							</Link>
              </div>
					</div>
				</div>
			)}
		</>
	);
}
