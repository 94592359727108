import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useParams } from "react-router-dom";
import { assetsUrl } from "../../config/settings.json";
import VivliaTeaser from "../../components/VivliaTeaser";

import "./Arthra.scss";

export default function Arthra(): ReactElement {
  const { appData } = useContext(AppContext);
 let { slug }: any = useParams();
  const results = Object.keys(appData).map((key) => appData[key]);

  const arthra = results.filter((v) =>
    v.dataType.toLowerCase().includes("publications")
  );

if (!slug) {
  slug = 'thrasivoulos-kontaxhs';
}
  let etairosID = '';
  if (arthra[0] && slug) {
    const etairoi = results.filter((v) => v.dataType.toLowerCase().includes("etairoi"));
    const etairos = etairoi[0].data.filter((v: any) => v.data.friendlyUrl.iv.toLowerCase().includes(slug));
    etairosID = etairos[0].id;
  } 
 
 

  return (
    <div className="Arthra-page">
      <div className="container">
        {arthra && (
          <div className="">
            <h2 className="Page-Title">{arthra[0]?.data.title["iv"]}</h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: arthra[0]?.data.description["iv"],
              }}
            />
            <div className="arthra">
              {arthra[0]?.data.publications["iv"].map((item: any, i: number) => (
                  
                  <div key={i} className="arthro" style={{
                     display: slug ? item.etairos.includes(etairosID) ? 'inherit' : 'none' : 'inherit'
                  }}>
                    <div className="arthro-front">
                      <div className="arthro-title">
                        {item.publicationTitle}
                      </div>
                      <div className="arthro-subtitle">
                        {item.publicationSubtitle}
                      </div>
                    </div>
                    <div className="arthro-back">
                      <div className="arthro-link-title">
                        Διαβάστε το πλήρες Άρθρο
                      </div>
                      <div className="arthro-link">
                        <Link
                          to={{
                            pathname: `${assetsUrl}/${item.publicationFile}`,
                          }}
                          target="_blank"
                        >
                          Αρχείο PDF
                        </Link>
                      </div>
                    </div>
                    <div className="arthro-bg"></div>
                    <div className="divider-bg"></div>
                    <div className="divider shadow extra"></div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <VivliaTeaser />
    </div>
  );
}
