import React, { ReactElement } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { assetsUrl } from '../../../config/settings.json';
import './EtairoiIndex.scss';

interface Props {
	data: any[];
}
export default function EtairoiIndex(props: Props): ReactElement {
	const { data } = props;
	const Etairoi = data[0]?.data;

	document.title = 'Εταιροι';

	return (
		<>
			<div className="EtairoiIndex container">
				<h2>ΕΤΑΙΡΟΙ</h2>
				{Etairoi && (
					<div className="EtairoiIndex-list ">
						{ Etairoi.map((etairos: any, i: number) => (
						<Fade triggerOnce={true}>
						<div className="EtairoiIndex-Etairos" key={i}>
								<div className="EtairoiIndex-left-column">
								<div className="img-zoom">

									{etairos.data['image']?.iv[0] && (
										<Link to={`/etairoi/${etairos.data['friendlyUrl']?.iv}`}>
											<img src={`${assetsUrl}/${etairos.data['image']?.iv[0]}?width=500`} alt="" />
										</Link>
									)}
									</div>
								</div>
								<div className="EtairoiIndex-right-column">
									<Link to={`/etairoi/${etairos.data['friendlyUrl']?.iv}`}>
										<div className="EtairoiIndex-title">{etairos.data['name']?.iv}</div>
									</Link>
									<div className="EtairoiIndex-subtitle">{etairos.data['title']?.iv}<br />{etairos.data.subTitle?.iv}</div>
									<div className="EtairoiIndex-description" dangerouslySetInnerHTML={{ __html: etairos.data['shortDescription']?.iv }} />
									<div className="EtairoiIndex-description-bold" dangerouslySetInnerHTML={{ __html: etairos.data['shortDescription2']?.iv }} />

									{etairos.data['socialLinks'].iv && (
										<div className="EtairoiIndex-links">
											{etairos.data['socialLinks']?.iv.map((item: any, i: number) => (
												<div className="EtairoiIndex-navItem" key={i}>
													<Link to={{ pathname: `${item.socialUrl}` }} target="_blank">
														<svg>
															<use xlinkHref={`#${item.socialName}`} />
														</svg>
													</Link>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</Fade>
						))}
					</div>
				)}
				</div>



		</>
	);

}
