import React, { ReactElement, useState } from "react";
import "./CookieConsent.scss";

const storedCookieConsent = localStorage.getItem("storedCookieConsent");

export default function CookiesConsent(): ReactElement {
  const [cookieConsent, setCookieConsent] = useState(storedCookieConsent);

  const CookieConsentAccept = (value: string) => {
    localStorage.setItem("storedCookieConsent", value);
    setCookieConsent(value);
  };

  return (
    <>
      {!cookieConsent && (
        <div className="CookieConsent">
          <div className="CookieConsent-inner">
            <div className="CookieConsent-content">
              <div className="title">Σημείωση για τη χρήση cookies</div>
              <div className="text">
                Χρησιμοποιούμε cookies για τον ποιοτικό έλεγχο των σελίδων μας,
                την παροχή προσωποποιημένης εμπειρίας στους επισκέπτες και τη
                βελτίωση των υπηρεσιών μας.
              </div>
            </div>
            <div className="CookieConsent-actions">
              <div className="btn-consent" onClick={() => CookieConsentAccept("1")}>
                ΟΚ
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
