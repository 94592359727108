import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { assetsUrl } from "../../../config/settings.json";
import "./FrontEtairos.scss";

interface Props {
  data: any[];
}
export default function FrontEtairos(props: Props): ReactElement {
  const { data } = props;
  var frontEtairos = Object.assign({}, ...data);

  return (
    <>
      {frontEtairos && (
        <div className="FrontEtairos">
          <div className="FrontEtairos-left-column img-zoom">
            {frontEtairos["frontPageEtairosImage"]?.iv[0] && (
              <Link to="/thrasivoulos-kontaxhs"><img
                src={`${assetsUrl}/${frontEtairos["frontPageEtairosImage"]?.iv[0]}?width=500`}
                alt=""
              /></Link>
            )}
            <div className="divider-bg"></div>
            <div className="divider shadow extra"></div>
          </div>
          <div className="FrontEtairos-right-column">
            <div className="FrontEtairos-title">
              {frontEtairos["frontPageEtairosName"]?.iv}
            </div>
            <div className="FrontEtairos-subtitle">
              {frontEtairos["frontPageEtairosSubtitle"]?.iv}
            </div>
            {frontEtairos["frontPageEtairosLinks"]?.iv && (
              <div className="FrontEtairos-links">
                {frontEtairos["frontPageEtairosLinks"]?.iv.map(
                  (item: any, i: number) => (
                    <div className="FrontEtairos-navItem" key={i}>
                      <Link to={item.linkUrl}>{item.linkTitle}</Link>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
