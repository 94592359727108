import React, { useState, useEffect, ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import "./Header.scss";

export default function Header(): ReactElement {
  const [mobileNav, setMobileNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const settings = results.filter((v) =>
    v.dataType.toLowerCase().includes("settings")
  );


  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 1024) {
          setMobileNav(false);
        }
      },
      false
    );
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let x = ["header"];
  if (scrolled) {
    x.push("scrolled");
  }
  if (mobileNav) {
    x.push("mobileActive");
  }
  useEffect(() => {
    mobileNav
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [mobileNav]);

  return (
    <header id="header" className={x.join(" ")}>
      <div className="container">
        {settings[0] && (
          <div className="header-inner">
            <Link to="/">
              <div className="brand">
                <div className="brand-logo">
                  <svg>
                    <use xlinkHref="#logo" />
                  </svg>
                </div>
                <div className="brand-text">
                  <h2>{settings[0].data.subtitle.iv}</h2>
                  <h1>{settings[0].data.title.iv}</h1>
                </div>
              </div>
            </Link>
            <nav>
              {settings[0].data.topNav.iv.map((item: any, i: number) => (
                <div className="navItem" key={i}>
                  <Link to={`/${item.friendlyUrl}`}>{item.text}</Link>
                </div>
              ))}
            </nav>
          </div>
        )}
      </div>
      <div className="mobile-nav">
        <div className="close-text">Κλεισιμο</div>
        <div className="nav-icon" onClick={() => setMobileNav(!mobileNav)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="overlay">
        <div className="container">
          {settings[0] && (
            <div
              className="mobile-menu"
              onClick={() => setMobileNav(!mobileNav)}
            >
              {settings[0].data.footerNav.iv.map((navItem: any, i: number) => (
                <div className="mobileNavItem" key={i}>
                  {navItem.frienlyUrl.includes("http") ? (
                    <Link
                      to={{ pathname: `${navItem.frienlyUrl}` }}
                      target="_blank"
                    >
                      {navItem.text}
                    </Link>
                  ) : (
                    <Link to={`/${navItem.frienlyUrl}`}>{navItem.text}</Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="divider-bg"></div>
      <div className="divider shadow"></div>
    </header>
  );
}
