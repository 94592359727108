import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { assetsUrl } from "../config/settings.json";

import "./Footer.scss";

export default function Footer(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const settings = results.filter((v) =>
    v.dataType.toLowerCase().includes("settings")
  );

  function sortByKey(array: [], key: any) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return parseInt(x) < parseInt(y) ? -1 : parseInt(x) > parseInt(y) ? 1 : 0;
    });
  }

  return (
    <>
    <footer>
      <div className="footer-links">
        <div className="container">
          <div className="footer-links-address">
            <div className="footer-links-item">
              {settings[0]?.data.address.iv}
            </div>
            <div className="footer-links-item">
              <strong>Τ </strong>
              <a href={`tel:${settings[0]?.data.phone.iv}`}>
                {settings[0]?.data.phone.iv}
              </a>
            </div>
            <div className="footer-links-item">
              <strong>F </strong> {settings[0]?.data.fax.iv}
            </div>
            <div className="footer-links-item">
              <strong>E </strong>
              <a href={`mailto:${settings[0]?.data.email.iv}`}>
                {settings[0]?.data.email.iv}
              </a>
            </div>
          </div>
          {settings[0]?.data.footerNav.iv && (
            <div className="footer-links-nav">
              {sortByKey(settings[0]?.data.footerNav.iv, "sortOrder").map(
                (navItem: any, i: number) => (
                  <div key={i} className="footer-links-item">
                    {navItem.frienlyUrl.includes("http") ? (
                      <Link
                        to={{ pathname: `${navItem.frienlyUrl}` }}
                        target="_blank"
                      >
                        {navItem.text}
                      </Link>
                    ) : (
                      <Link to={`/${navItem.frienlyUrl}`}>{navItem.text}</Link>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>

        {/* {settings[0] && (
          <div className="container --footer-banner">
            <Link
              to={{
                pathname: `/page/${settings[0].data?.footerBannerUrl.iv}`,
              }}
            >
              <img
                src={`${assetsUrl}/${settings[0].data?.footerBannerImage.iv[0]}/?width=800}`}
                alt=""
              />
            </Link>
          </div>
        )} */}
      </div>
      <div className="footer-legal">
        <div className="container">
          {settings[0]?.data.legalNavTexts.iv && (
            <div className="footer-legal-link-nav">
              <div className="footer-legal-link-item">
                &copy;{new Date().getFullYear()}{" "}
                {settings[0]?.data.footerLegalText.iv}{" "}
              </div>
              {sortByKey(settings[0]?.data.legalNavTexts.iv, "sortOrder").map(
                (item: any, i: number) => (
                  <div key={i} className="footer-legal-link-item">
                    {item.friendlyUrl.includes("http") ? (
                      <Link
                        to={{ pathname: `${item.friendlyUrl}` }}
                        target="_blank"
                      >
                        {item.text}
                      </Link>
                    ) : (
                      <Link to={`/page/${item.friendlyUrl}`}>{item.text}</Link>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </footer>
    {/* {settings[0] && (
    <div className="espa">
    <Link to={{
                pathname: `/page/${settings[0].data?.footerBannerUrl.iv}`,
              }}>
       <img
                src={`${assetsUrl}/${settings[0].data?.espaBanner.iv[0]}/?width=250}`}
                alt=""
              />
    </Link>
 </div>
    )} */}
</>
  );
}
