import React from 'react';
import {version} from '../../package.json';

const isProduction = process.env.NODE_ENV === 'production';
const storedVersion = localStorage.getItem('version');
if (!storedVersion) localStorage.setItem('version', version);
let currentVersion: string;

type Props = {
  routerProps: any;
};

export default class CacheBuster extends React.Component<Props> {
  componentDidMount() {
    if (isProduction) this.fetchMeta();
  }

  componentDidUpdate(prevProps: Props) {
    const {pathname} = this.props.routerProps.location;
    if (pathname !== prevProps.routerProps.location.pathname) {
      if (isProduction) this.fetchMeta();
    }
  }

  fetchMeta() {
    const timestamp = +new Date();
    fetch(`/dist/meta.json?v=${timestamp}`)
      .then((response) => response.json())
      .then((json) => {
        currentVersion = json.version;
        if (currentVersion !== storedVersion) {
          this.refreshCacheAndReload();
        }
      });
  }

  refreshCacheAndReload() {
    localStorage.clear();
    localStorage.setItem('version', currentVersion);

    if (caches) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload();
  }

  render() {
    return this.props.children;
  }
}
