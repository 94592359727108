import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { assetsUrl } from '../../../config/settings.json';
import './Tomeis.scss';
interface Props {
	data: any[];
}
export default function Tomeis(props: Props): ReactElement {
	const { data } = props;
	var tomeis = Object.assign({}, ...data);

	return (
		<>
			{tomeis && (
				<div className="Teaser">
					<div className="Teaser-top ">
						<svg>
							<use xlinkHref="#logo" />
						</svg>
						<div className="Teaser-title">{tomeis['section2Title']?.iv}</div>
						<div className="Teaser-text">{tomeis['section2Text']?.iv}</div>
					</div>
					<div className="Teaser-bottom ">
						
						<div className="Teaser-publications-texts">
							<div className="Teaser-publications-title">{tomeis['section2TeaserTitle']?.iv}</div>
							<div className="Teaser-publications-text">{tomeis['section2TeaserText']?.iv}</div>
							<Link to="/vivlia-monografies" className="Teaser-publications-link">
								<svg>
									<use xlinkHref="#right-arrow" />
								</svg>
							</Link>
						</div>
						{tomeis['section2TeaserImage']?.iv[0] && (
							<div className="Teaser-publications-image" style={{
								backgroundImage: `url(${assetsUrl}/${tomeis['section2TeaserImage']?.iv[0]}?width=700)`
							}}>
							</div>
						)}
						<div className="divider-bg"></div>
            <div className="divider extra shadow"></div>
					</div>
				</div>
			)}
		</>
	);
}
