import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import './ArthraTeaser.scss';

interface Props {
  etairos: any
}

export default function ArthraTeaser(props: Props): ReactElement {
  const { appData } = useContext(AppContext);
  const { etairos } = props;

  const results = Object.keys(appData).map((key) => appData[key]);
  const arthra = results.filter((v) =>
    v.dataType.toLowerCase().includes("publications")
  );
  

  return (
    <>
      {arthra && (
        <div className="ArthraTeaser">
          <div className="container">
          <Link to={`/kyries-dimosieyseis/${etairos}`} className="Teaser-link">
            <div className="Teaser-texts ">
              <div className="Teaser-title">
              ΑΡΘΡΑ / ΔΗΜΟΣΙΕΥΣΕΙΣ
              </div>
              <div className="Teaser-text" dangerouslySetInnerHTML={{ __html: arthra[0]?.data.description["iv"] }} />
            </div>
          </Link>
          </div>
        </div>
      )}
    </>
  );
}
