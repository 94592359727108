import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { assetsUrl } from "../../../config/settings.json";
import "./EtairoiDetails.scss";
import ArthraTeaser from "../../../components/ArthraTeaser";
import SymmetoxesTeaser from "../../../components/SymmetoxesTeaser";
import VivliaTeaser from "../../../components/VivliaTeaser";

interface Props {
  data: any[];
  slug: any;
}
export default function EtairoiDetails(props: Props): ReactElement {
  const { data, slug } = props;
  const etairoi = data[0]?.data;

  

  return (
    <>
      {etairoi && (
        <div className="EtairoiDetails">
          <div className="Etairoi-list">
            {etairoi
              .filter((v: { data: { friendlyUrl: { iv: string } } }) =>
                v.data.friendlyUrl.iv.toLowerCase().includes(slug)
              )
              .map((etairos: any, i: number) => (
                <div key={i} className="Etairos-container ">
                  <div className="etairos-inner container">
                    <div className="EtairoiIndex-Etairos">
                      <div className="EtairoiIndex-left-column">
                        <div className="img-zoom">
                          {etairos.data["image"]?.iv[0] && (
                            <img
                              src={`${assetsUrl}/${etairos.data["image"]?.iv[0]}?width=500`}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                      <div className="EtairoiIndex-right-column">
                        <div className="EtairoiIndex-title">
                          {etairos.data["name"]?.iv}
                        </div>
                        <div className="EtairoiIndex-subtitle">
                        {etairos.data['title']?.iv}<br />{etairos.data.subTitle?.iv}
                        </div>
                        {/* <div
                          className="EtairoiIndex-description"
                          dangerouslySetInnerHTML={{
                            __html: etairos.data["shortDescription"]?.iv,
                          }}
                        /> */}
                        {/* <div
                          className="EtairoiIndex-description-bold"
                          dangerouslySetInnerHTML={{
                            __html: etairos.data["shortDescription2"]?.iv,
                          }}
                        /> */}

                        {etairos.data["socialLinks"].iv && (
                          <div className="EtairoiIndex-links">
                            {etairos.data["socialLinks"]?.iv.map(
                              (item: any, i: number) => (
                                <div className="EtairoiIndex-navItem" key={i}>
                                  <Link
                                    to={{ pathname: `${item.socialUrl}` }}
                                    target="_blank"
                                  >
                                    <svg>
                                      <use xlinkHref={`#${item.socialName}`} />
                                    </svg>
                                  </Link>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Etairoi-cv ">
                      <div className="cv-title">ΣΥΝΤΟΜΟ ΒΙΟΓΡΑΦΙΚΟ</div>
                      <div
                        className="cv-text"
                        dangerouslySetInnerHTML={{
                          __html: etairos.data["cv"]?.iv,
                        }}
                      />
                    </div>
                  </div>

                  {etairos.data["arthra"]?.iv === true ? (
                    <ArthraTeaser etairos={slug} />
                  ) : (
                    <></>
                  )}

                  {etairos.data["symmetoxes"]?.iv === true ? (
                    <SymmetoxesTeaser />
                  ) : (
                    <></>
                  )}

                  {etairos.data["vivlia"]?.iv === true ? (
                    <VivliaTeaser />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
