import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Fade } from "react-awesome-reveal";
import { assetsUrl } from "../config/settings.json";
import './VivliaTeaser.scss';

export default function VivliaTeaser(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const vivlia = results.filter((v) =>
    v.dataType.toLowerCase().includes("vivlia")
  );

  return (
    <>
      {vivlia && vivlia[0]?.data.vivliaImage["iv"][0] && (
        <div
          className="VivliaTeaser"
          style={{
            backgroundImage: `url(${assetsUrl}/${vivlia[0]?.data.vivliaImage["iv"][0]}?width=2000)`,
          }}
        >
          <div className="mobileImage" style={{
            backgroundImage: `url(${assetsUrl}/${vivlia[0]?.data.vivliaImage["iv"][0]}?width=2000)`,
          }}></div>
          <div className="container">
          <Link to="/vivlia-monografies" className="Teaser-publications-link">
            <div className="Teaser-texts ">
              <Fade triggerOnce={true} direction="left">
              <div className="Teaser-title">
                {vivlia[0]?.data.vivliaTitle["iv"]}
              </div>
              </Fade>
              <Fade triggerOnce={true} delay={300} direction="left">
              <div className="Teaser-text">
                {vivlia[0]?.data.vivliaShortText["iv"]}
              </div>
              </Fade>
            </div>
          </Link>
          </div>
        </div>
      )}
    </>
  );
}
