import React, { ReactElement, useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { Fade } from 'react-awesome-reveal';

import VivliaTeaser from '../../components/VivliaTeaser';
import './Tomeis.scss';


export default function Tomeis(): ReactElement {
	const { appData } = useContext(AppContext);
	const results = Object.keys(appData).map((key) => appData[key]);
	const tomeis = results.filter(v => v.dataType.toLowerCase().includes('tomeis'));

	
	return (
		<div className="Tomeis-page">
			<div className="container">
					 {tomeis && (
						<div className="">
							<h2 className="Page-Title">{tomeis[0]?.data.title['iv']}</h2>
							<div className="Page-description" dangerouslySetInnerHTML={{ __html: tomeis[0]?.data.introText['iv'] }} />

							<Fade  triggerOnce={true}>
							{ tomeis[0]?.data.tomeisList['iv'].map((item: any, i: number) => (
									<div key={i} className="Page-item-description" dangerouslySetInnerHTML={{ __html: item.tomeasText }} />
									))}
								</Fade>
							</div>

					)} 
			</div>
			<VivliaTeaser  />

		</div>
	);
}
