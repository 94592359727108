import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import ContactForm from "./ContactForm";
import "./Contact.scss";

export default function Contact(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const settings = results.filter((v) =>
    v.dataType.toLowerCase().includes("settings")
  );
  return (
    <div className="Contact-page">
      <div className="Contact-form">
        <div className="container">
          <div className="Contact-form-left">
            <div className="Contact-form-left-top">
              <div className="Contact-form-title">Επικοινωνία</div>
              <div className="Contact-form-subtitle">
              <p>Μπορείτε να επικοινωνήσετε μαζί μας συμπληρώνοντας την φόρμα επικοινωνίας και
              θα σας απαντήσουμε το συντομότερο δυνατό.
              </p>
              </div>
            </div>
            <div className="Contact-form-left-bottom">
              <div className="contact-form-logo">
                <svg>
                  <use xlinkHref="#logo" />
                </svg>
              </div>
              {settings && (
                <div className="contact-form-address">
                  <div className="address-item">
                    {settings[0]?.data.address.iv}
                  </div>
                  <div className="address-item">
                    <strong>Τ </strong>
                    <a href={`tel:${settings[0]?.data.phone.iv}`}>{settings[0]?.data.phone.iv}</a>
                  </div>
                  <div className="address-item">
                    <strong>F </strong> {settings[0]?.data.fax.iv}
                  </div>
                  <div className="address-item">
                    <strong>E </strong> <a href={`mailto:${settings[0]?.data.email.iv}`}>{settings[0]?.data.email.iv}</a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="Contact-form-right">
            <ContactForm />
          </div>
        </div>
      </div>
      <div className="Contact-map">
        <div className="container">
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3144.5163306096847!2d23.7474688!3d37.9884154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd4d47789c6b%3A0x27683bb0be60f6cc!2sLeof.%20Alexandras%20126%2C%20Athina%20114%2075!5e0!3m2!1sen!2sgr!4v1622388090387!5m2!1sen!2sgr"
              title="Χάρτης"
              width="600"
              height="450"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
