import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Fade } from "react-awesome-reveal";
import {assetsUrl} from '../../config/settings.json';
import "./Syndesmoi.scss";
import { Link } from "react-router-dom";

export default function Syndesmoi(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const syndesmoi = results.filter((v) => v.dataType === "syndesmoi");

  return (
    <div className="Syndesmoi-page">
      <div className="container">
        <div className="syndesmoi-intro">
          <h2 className="Page-Title">Σύνδεσμοι</h2>
        </div>
        {syndesmoi && (
          <div className="syndesmoi">
            {syndesmoi[0]?.data.map((link: any, i: number) => (
              <div className="syndesmos" key={i}>
                  <Fade triggerOnce={true}>
                <div className="syndesmos-image">
                <Link to={{ pathname: `${link.data.link?.iv }` }} target="_blank">
                  <img src={`${assetsUrl}/${link.data.linkImage?.iv[0]}?width=300&height=100&mode=Max`} alt="" />
                  </Link>
                  </div>
              </Fade>
                <div className="syndesmos-title">{link.data.linkTitle?.iv}</div>
                <div className="syndesmos-link"><Link to={{ pathname: `${link.data.link?.iv }` }} target="_blank">{link.data.link?.iv}</Link></div>

              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
