import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { assetsUrl } from "../../../config/settings.json";
import "./Hero.scss";
interface Props {
  data: any[];
}

export default function Hero(props: Props): ReactElement {
  const { data } = props;
  var hero = Object.assign({}, ...data);

  return (
    <>
      {hero && hero["introImage"] && (
        <div
          className="Hero"
          style={{
            backgroundImage: `url(${assetsUrl}/${hero["introImage"]?.iv[0]}?width=1920)`,
          }}
        >
          <div className="container">
            <div className="Hero-inner">
              <Fade direction="right" triggerOnce={true}>
                <div className="Hero-title">{hero["introTitle"]?.iv}</div>
              </Fade>
              <Fade direction="right" delay={300} triggerOnce={true}>
                <div
                  className="Hero-text"
                  dangerouslySetInnerHTML={{
                    __html: hero["introText"]?.iv,
                  }}
                />
              </Fade>
              <Fade direction="right" delay={600} triggerOnce={true}>
                <div className="Hero-link">
                  <Link to="/about">{hero["introLinkText"]?.iv}</Link>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
