import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";

import ScrollToTop from "./lib/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";

import Home from "./pages/Home/Home";
import Etairoi from "./pages/Etairoi/Etairoi";
import Tomeis from "./pages/Tomeis/Tomeis";
import Arthra from "./pages/Arthra/Arthra";
import Vivlia from "./pages/Vivlia/Vivlia";
import Symmetohes from "./pages/Symmetohes/Symmetohes";
import Synergates from "./pages/Synergates/Synergates";
import Syndesmoi from "./pages/Syndesmoi/Syndesmoi";
import About from "./pages/About/About";
import Page from "./pages/Page/Page";
import Contact from "./pages/Contact/Contact";
import CookieConsent from "./components/CookieConsent";
import CacheBuster from "./components/CacheBuster";
import { SEO } from "./components/SEO";

import { ReactComponent as UISvg } from "./assets/ui/ui.svg";

function App(): ReactElement {
  return (
    <Route render={(routerProps: any) => (
      <CacheBuster routerProps={routerProps}>
        <AppContextProvider>
          <SEO />
          <ScrollToTop />

          <Header />
          <div className="main">
            <Switch>
              <Route path="/" exact render={() => <Home />} />
              <Route exact path="/about" render={() => <About />} />
              <Route
                exact
                path="/etairoi"
                render={(routerProps: any) => (
                  <Etairoi routerProps={routerProps} id="" />
                )}
              />
              <Route
                exact
                path="/etairoi/:slug"
                render={(routerProps: any) => (
                  <Etairoi routerProps={routerProps} id={null} />
                )}
              />
              <Route
                exact
                path="/thrasivoulos-kontaxhs"
                render={(routerProps: any) => (
                  <Etairoi
                    routerProps={routerProps}
                    id="thrasivoulos-kontaxhs"
                  />
                )}
              />
              <Route
                exact
                path="/tomeis-exeidikeyshs"
                render={() => <Tomeis />}
              />
              <Route
                exact
                path="/synergazomenoi-dikhgoroi"
                render={() => <Synergates />}
              />
              <Route exact path="/syndesmoi" render={() => <Syndesmoi />} />
              <Route
                exact
                path="/vivlia-monografies"
                render={() => <Vivlia />}
              />
              <Route exact path="/symmetohes" render={() => <Symmetohes />} />
              <Route
                exact
                path="/kyries-dimosieyseis"
                render={() => <Arthra />}
              />
              <Route
                exact
                path="/kyries-dimosieyseis/:slug"
                render={() => <Arthra />}
              />
              <Route exact path="/epikoinwnia" render={() => <Contact />} />
              <Route
                exact
                path="/page/:slug"
                render={(routerProps: any) => (
                  <Page routerProps={routerProps} />
                )}
              />
            </Switch>
          </div>
          <Footer />
          <CookieConsent />
          <ScrollToTopButton />
          <UISvg />
        </AppContextProvider>
      </CacheBuster>
    )} />
  );
}

export default App;
