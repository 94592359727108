import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {apiUrl} from '../config/settings.json';


export interface AppContextProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
	appData: any;
	setAppData: (appData: any[]) => void;

}

interface AppContextProviderProps {
  children: any;
}

export const AppContext = React.createContext<AppContextProps>({
  isLoading: false,
  setIsLoading: () => null,
	appData: [],
	setAppData: () => {},

});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
  const [isLoading, setIsLoading] = useState(true);
	const [appData, setAppData] = useState({});




	
	async function fetchSettings() {
		
		const settings = axios.get(`${apiUrl}/settings`);
		const meta = axios.get(`${apiUrl}/pagemeta`);

		const homepage = axios.get(`${apiUrl}/homepage`);
		const etairoi = axios.get(`${apiUrl}/etairoi/?$orderby=data/sortOrder/iv`);
		const publications = axios.get(`${apiUrl}/publications`);
		const symmetoxes = axios.get(`${apiUrl}/symmetoxes`);
		//const synergates = axios.get(`${apiUrl}/synergates`);
		const synergates = axios.get(`${apiUrl}/synergazomenoi/?$orderby=data/area/iv`);
		const synergatespage = axios.get(`${apiUrl}/synergatespage`);
		const tomeis = axios.get(`${apiUrl}/tomeis`);
		const vivlia = axios.get(`${apiUrl}/vivlia`);
		const pages = axios.get(`${apiUrl}/pages`);
		const syndesmoi = axios.get(`${apiUrl}/syndesmoi`);
		await axios.all([settings, meta, homepage, etairoi, publications, symmetoxes, synergates, tomeis, vivlia, synergatespage, pages, syndesmoi ])
		.then(
			axios.spread((...res) => {
				
				var AllData: { dataType: any; data: any; }[] = [];

				let schema = null;
				let data = [];
				
				for (const [, value] of Object.entries(res)) {
					schema = value.data.items[0]['schemaName'];
					value.data.items.length > 1 ? data = value.data.items : data = value.data.items[0].data
				
					AllData.push({
						dataType: schema,
						data: data
					})
				}
				setAppData(AllData);
			})).catch(errors => {
				// react on errors.
			})
			
		}
		
		
		
		useEffect(() => {
			fetchSettings();

  }, []);

  // useEffect(() => {
  //   isLoading ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  // }, [isLoading]);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        // navIsOpen,
        // setNavIsOpen,
				appData,
				setAppData,
      }}
    >
      {appData && children}
    </AppContext.Provider>
  );
};

AppContext.displayName = 'AppContext';
