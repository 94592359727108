import React, { ReactElement} from "react";
import './Loader.scss';

export default function Loader(): ReactElement {
  

  
  return (
  <div className="loader">Loading...</div>
  
  );
}
