import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Fade } from 'react-awesome-reveal';
import { assetsUrl } from "../../config/settings.json";
import "./Vivlia.scss";



export default function Vivlia(): ReactElement {
  const { appData } = useContext(AppContext);
  const results = Object.keys(appData).map((key) => appData[key]);
  const vivlia = results.filter((v) => v.dataType.toLowerCase().includes("vivlia"));

  return (
    <div className="Vivlia-page">
      <div className="container">
        {vivlia && (
          <div className="">
            <h2 className="Page-Title">{vivlia[0]?.data.vivliaTitle["iv"]}</h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: vivlia[0]?.data.vivliaText["iv"],
              }}
            />

            {vivlia[0]?.data.vivliaList["iv"].map((item: any, i: number) => (
              <Fade triggerOnce={true}>
                <div
                key={i}
                className="Vivlio"
              >
                <div
                className="mobileImage"
                style={{
                  backgroundImage: `url(${assetsUrl}/${item.vivlioImage}/?width=1500)`,
                }}
              ></div>
                <div className="Vivlio-content">
                  <div className="vivlio-title">{item.vivlioTitle}</div>
                  <div className="vivlio-subtitle">{item.vivlioSubtitle}</div>
                  <div
                    className="vivlio-text"
                    dangerouslySetInnerHTML={{ __html: item.vivlioText }}
                  />
                </div>
              </div>
              </Fade>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
