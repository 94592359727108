import React, { ReactElement, useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
// import isEmail from "validator/lib/isEmail";
import {
  apiUrl,
  authUrl,
  cliendID,
  cliendSecret,
} from "../../config/settings.json";
export default function ContactForm(): ReactElement {
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  
  const handleValidation = (values: any) => {
    let errors: any = {};

    for (const key in values) {
      const value = `${values[key]}`;

      if (!value) {
        errors[key] = "To πεδίο είναι απαραίτητο";
      }

      // if (value) {
      //   switch (key) {
      //     case 'fullName':
      //       value.length >= 3 ? null : (errors[key] = "Παρακαλώ συμπληρώστε το όνομά σας (τουλάχιστον τρεις χαρακτήρες)");
      //       break;
      //     case 'email':
      //       isEmail(value) ? null : (errors[key] = "Το email δεν είναι σωστό");
      //       break;
      //     case 'message':
      //       value.length >= 5 ? null : (errors[key] = "Παρακαλώ συμπληρώστε το μήνυμά σας (τουλάχιστον τρεις χαρακτήρες)");
      //       break;
      //   }
      // }
    }

    return errors;
  };

  const onChange = (
    e: React.ChangeEvent<any>,
    handleChange: (e: React.ChangeEvent<any>) => void
  ) => {
    const { name, value } = e.target;
    let rx;

    switch (name) {
      case "phone":
        rx = new RegExp(/^([26]\d{0,9})?$/);
        break;
    }

    if (rx && !rx.test(value)) return;
    handleChange(e);
  };

  const onSubmit = (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void
  ) => {
    const url = `${apiUrl}/contactform/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', cliendID);
    params.append('client_secret', cliendSecret);
    params.append('scope', 'squidex-api');
   

    axios({
      method: "post",
      url: authUrl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json",
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        
        axios.post(url, (values), {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
        }).then((response) => {
          setSubmitting(false);
          if (response) {
            resetForm();
            setIsFormSuccess(true);
          }
        });

      })
      .catch((err) => {
        //console.log(err);
      });

    // const authData = `grant_type=client_credentials&client_id=${cliendID}=&client_secret=${cliendSecret}&scope=squidex-api`;

    //     axios({
    //       method: 'POST',
    //       url: authUrl,
    //       headers: {
    //           // Authorization: `Bearer ${token}`,
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       data: authData
    //   })
    //   .then(response => {
    //       console.log(response);
    //   })
    //   .catch(error => {
    //       console.log(error);
    //   });

    // console.log(JSON.stringify(values))
        
  };

  return (
    <div className="ContactForm">
      {isFormSuccess && (
        <div className="ContactForm-success">
          <div className="text">Το μήνυμά σας εστάλει με επιτυχία!</div>
        </div>
      )}

      <Formik
        initialValues={{ fullName: "", email: "", message: "" }}
        validate={(values) => {
          return handleValidation(values);
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ errors, touched, handleChange, isSubmitting }) => (
          <Form id="contact-form" className="form">
            <div className="form-fields">
              <div className="form-row">
                <div
                  className={`form-control ${
                    touched.fullName && errors.fullName ? "has-error" : ""
                  }`}
                >
                  <Field type="text" name="fullName['iv']" id="fullName" />
                  <label htmlFor="fullName">ΟΝΟΜΑΤΕΠΩΝΥΜΟ</label>
                  {touched.fullName && errors.fullName && (
                    <p className="error-message">{errors.fullName}</p>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div
                  className={`form-control ${
                    touched.email && errors.email ? "has-error" : ""
                  }`}
                >
                  <Field
                    type="email"
                    name="email['iv']"
                    id="email"
                    placeholder="Email"
                  />
                  <label htmlFor="email">EMAIL</label>
                  {touched.email && errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
              </div>

              <div className="form-row form-row-message">
                <div
                  className={`form-control ${
                    touched.message && errors.message ? "has-error" : ""
                  }`}
                >
                  <Field
                    as="textarea"
                    name="message['iv']"
                    id="message"
                    onChange={(e: React.ChangeEvent<any>) => {
                      e.currentTarget.style.height = `auto`;
                      e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                      onChange(e, handleChange);
                    }}
                  />
                  <label htmlFor="message">MHNYMA</label>
                  {touched.message && errors.message && (
                    <p className="error-message --textarea">{errors.message}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-action">
              <button className="button " type="submit" disabled={isSubmitting}>
                <span className="text">Αποστολή</span>
                <svg>
                  <use xlinkHref="#right-arrow" />
                </svg>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
