import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../context/AppContext";

interface Props {
	routerProps: any;
}

export default function Etairoi(props: Props): ReactElement {
  const { appData } = useContext(AppContext);
  const { routerProps } = props;
	const slug = routerProps.match.params.slug;
  const results = Object.keys(appData).map((key) => appData[key]);

	const pages = results.filter(v => v.dataType.toLowerCase().includes('pages'));
  const page = pages[0]?.data.filter((v: { data: { friendlyUrl: { iv: string } } }) =>  v.data.friendlyUrl.iv.toLowerCase().includes(slug));

  return (
    <div className="Vivlia-page">
      <div className="container">
        
         {page && (
          <div className="">
            <h2 className="Page-Title">{page[0]?.data.pageTitle["iv"]}</h2>
            <div
              className="Page-description"
              dangerouslySetInnerHTML={{
                __html: page[0]?.data.pageText["iv"],
              }}
            />
            </div>
         )}


      </div>
    </div>
  );
}
