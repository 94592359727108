import React, { ReactElement, useEffect, useState} from "react";
import './ScrollToTopButton.scss';

export default function ScrollToTopButton(): ReactElement {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 400) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });


  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }



  
  return (
<div className="scrollTop" 
      style={{
        visibility: showScrollTop ? 'visible' : 'hidden',
        opacity: showScrollTop ? '1' : '0'
        }}>
        <div className="container">
          <div className="upArrow" onClick={() => scrollToTop()}>
          <svg>
            <use xlinkHref="#right-arrow" />
          </svg>
          </div>
        </div>
      </div>
  );
}
